/*
 * @Author: Jayden
 * @Date: 2024-08-15 10:20:10
 * @Last Modified by: Jayden
 * @Last Modified time: 2024-08-15 13:43:50
 *  官网报价说明和禁限运品清单
 */
import { Box } from '@mui/material'
import { navigate } from 'gatsby'
export const ProhibitedParcelsContent_EN = () => {
  return (
    <Box
      sx={{
        h1: {
          textAlign: 'center',
          p: '30px 0 20px',
        },
        h3: { p: '7px 0' },
        '.item': { fontSize: 14, p: '4px 0' },
      }}
    >
      {/* <h1>Terms of Service</h1>
      <h3>1. Quotation</h3>
      1.1 The above amounts are charged by the W2D service (iMile CD for recipient)
      <br />
      1.2 The above values do not include ICMS/ISS.
      <br />
      <h3>2. Ad-Valorem:</h3>
      2.1 Ad-Valorem: 2% (minimum rate R $2.00)-
      <br />
      Value referring to the cost of the insurance policy of the merchandise, to be applied to the
      value of the invoice of the transported merchandise
      <br />
      <h3>3. Size restriction</h3>
      3.1 Length of each side can not exceed 100cm.
      <br />
      <h3>4. Weight restriction</h3>
      4.1 The weight of an order cannot exceed 30kgs;
      <br />
      4.2 The greater value between the cubed weight and the actual weight will be considered.
      <br />
      Cubic weight = Volume (cm ³)/6000.
      <h3>5. Return Fee:</h3>
      5.1 100% of freight
      <br />
      <h3>6. Compensation terms:</h3>
      6.1 Deadline for acceptance of compensation: Requests must be made within 30 days of receipt
      of the goods by iMile. Complaints after 30 days will not be accepted.
      <br />
      6.2 iMile requires that the invoice value of a single package does not exceed R $1,000. For
      orders damaged or lost by iMile, the total value of the invoice of the lost or damaged part of
      the order will be refunded (if contracted ad-valorem).
      <br />
      In the absence of ad-valorem, the shipping fee will be waived, but no compensation will be
      paid for loss and/or damage of orders.
      <br />
      6.3 Fragile Products and Packaging: In order for us to accept liability as described, the
      packaging of the Goods must be compatible with the handling of the Goods in various modes of
      transport. IMILE cannot be held responsible for broken goods when the outer packaging is not
      damaged, which may indicate that the goods inside the packaging are substandard.
      <br />
      <h3>7. Restrictions for Product Acceptance:</h3>
      7.1 Dangerous orders, post, etc. will not be accepted. For more details, please check "List of
      Prohibited Items".
      <br />
      <h3>
        8.Website for order tracking:{' '}
        <a onClick={() => navigate('/track')} style={{ fontSize: 14 }}>
          http://www.imile.com
        </a>
      </h3>
      Note: Once the customer agrees and accepts the services of our company, it is considered that
      the customer has read in detail the observations of this price table and delivery policies,
      and accepts the restrictions of these terms.
      <br />
      <br /> */}
      <h1>List of prohibited parcels (embargoed)</h1>
      <div className='item'>
        1. Live animals (including, but not limited to: mammals, reptiles, fish, invertebrates,
        amphibians, birds, insects, larvae and pupae) or embryos.{' '}
      </div>
      <div className='item'>
        2. Perishable groceries, food and beverages that require refrigeration or other
        environmental controls. Any product that breaks easily or emits an unpleasant odor.
      </div>
      <div className='item'>
        3. Carcasses of animals, insects and/or domestic animals. Whole or wholly processed (dried)
        hunting or taxidermy trophies or parts of animals, such as ivory or others, prepared by
        classifier. Animal skins, ashes or animal by-products.
      </div>
      <div className='item'>
        4. Plants and plant materials, including cut flowers, herbs, stems, branches, leaves or any
        by-products of plant origin.
      </div>
      <div className='item'>
        5. Human cadavers, organs or body parts, human embryos, cremated (ashes) or exhumed remains.
      </div>
      <div className='item'>
        6. Precious metals (gold bars or fine jewellery) and loose precious or semi-precious stones
        or jewellery (cut or not, polished or not).
      </div>
      7. Money (currency, banknotes, foreign currency notes, coins, etc.), bearer cheques or credit
      instruments, negotiable documents, promissory notes, bonds or any other documents with nominal
      value.
      <div className='item'>
        8. Billing or Advertising of Foreign Lottery Tickets and Instant Lottery
      </div>
      9. Weapons of any kind: originals, imitations, 3D printers or phantom weapons (without serial
      numbers) and/or gun parts, ammo etc. helpers for automatic weapons (warheads) and other rapid
      fire triggers. Any firearm or other weapon.
      <div className='item'>
        10. Items that are irreplaceable, priceless or difficult to replace, such as art, antiques
        or other goods.
      </div>
      <div className='item'>
        11. Dynamite, gunpowder, firecrackers or any explosive substance. Explosives/explosive
        devices, such as bombs, grenades or other devices.
      </div>
      <div className='item'>
        12. hazardous waste, such as medical, toxic, chemical, radioactive or any hazardous waste
        within the meaning of the Regulation on the Inland Transport of Dangerous Goods.
      </div>
      <div className='item'>13. Toxic, irritating and infectious substances.</div>
      <div className='item'>
        14. Chemical, corrosive, oxidizing, flammable, radioactive, magnetic and other hazardous
        substances, and any others mentioned in the Regulation on the Inland Transport of Dangerous
        Goods and Wastes.
      </div>
      <div className='item'>
        15. Counterfeit products, including but not limited to trademarks that are identical or
        substantially indistinguishable from trademarks without the approval or supervision of the
        trademark owner (also commonly referred to as "counterfeit products").
      </div>
      <div className='item'>
        16. Cannabis, including cannabis for recreational or medicinal use, and cannabis-derived
        cannabidiol ("CBD"): any product that contains thyroid cannabinol ("THC") and synthetic{' '}
        cannabinoids. Raw or unrefined cannabis plants, or subparts and derivatives thereof
        (including but not limited to cannabis stems, leaves, oils, flowers and seeds and
        cannabis-derived CBD).
      </div>
      <div className='item'>
        17. Narcotic drugs and psychotropic substances (precursor chemicals and basic chemicals).
      </div>
      <div className='item'>18.Laboratory samples (toxic, hazardous or specially treated).</div>
      <div className='item'>
        19.Goods which require us to obtain specific licences or authorisations or which are subject
        to any specific legal restrictions without such specific permission for carriage.
      </div>
      <div className='item'>20. Obscene material restricted by law.</div>
      <div className='item'>
        21. any other goods prohibited by the specific legislation of the matter.
      </div>
      <div className='item'>22. Damp, leaking or smelly packaging.</div>
      <div className='item'>
        23. Goods that may cause damage or delay to equipment, personnel or other property.
      </div>
    </Box>
  )
}

export const ProhibitedParcelsContent_PT = () => {
  return (
    <Box
      sx={{
        h1: {
          textAlign: 'center',
          p: '30px 0 20px',
        },
        h3: { p: '7px 0' },
        '.item': { fontSize: 14, p: '4px 0' },
      }}
    >
      {/* <h1>Termos de Serviço</h1>
      <h3>1. Cotação</h3>
      1.1 Os valores acima são cobrados pelo serviço de W2D (CD iMile para destinatário)
      <br />
      1.2 Os valores acima não incluem ICMS/ISS.
      <br />
      <h3>2. Ad-Valorem:</h3>
      2.1 Ad-Valorem: 2%（Tarifa mínima R$ 2,00） -
      <br />
      Valor referente ao custo de apólice de Seguro da mercadoria, a ser aplicado sobre o valor da
      Nota fiscal da mercadoria transportada
      <br />
      <h3>3. Restrição de tamanho</h3>
      3.1 Comprimento de cada lado não pode exceder 100cm.
      <br />
      <h3>4. Restrição de peso</h3>
      4.1 O peso de uma encomenda não pode ultrapassar 30Kgs;
      <br />
      4.2 Será considerado o maior valor entre o peso cubado e o peso real.
      <br />
      Peso cubado = Volume(cm³) /6000.
      <h3>5. Taxa de devolução:</h3>
      5.1 100% do frete
      <br />
      <h3>6. Termos de compensação:</h3>
      6.1 Prazo para aceite da compensação: As solicitações devem ser feitas dentro de 30 dias após
      o recebimento da mercadoria pela iMile. Reclamações após estes 30 dias não serão aceitas.
      <br />
      6.2 A iMile exige que o valor da fatura de um único pacote não exceda R$ 1.000. Para
      encomendas danificadas ou extraviadas pela iMile, será reembolsado o valor total da Nota
      fiscal da parte perdida ou danificada da encomenda (caso contratado o Ad-Valorem).
      <br />
      Na ausência de Ad-Valorem, a taxa de envio será dispensada, mas nenhuma compensação será paga
      por perda e/ou dano de encomendas.
      <br />
      6.3 Produtos e embalagens frágeis: Para que possamos aceitar a responsabilidade conforme
      descrito, a embalagem das mercadorias deve ser compatível com o manuseio das mercadorias em
      vários modos de transporte. A iMile não poderá ser responsabilizada por mercadorias quebradas
      quando a embalagem externa não estiver danificada, o que pode indicar que as mercadorias
      dentro da embalagem estão abaixo do padrão.
      <br />
      <h3>7. Restrições para aceite do Produto:</h3>
      7.1 Encomendas perigosas, pós, etc não serão aceitas. Para mais detalhes, favor verificar
      “lista de itens Proibidos”.
      <br />
      <h3>
        8.Site para rastreio da encomenda:{' '}
        <a onClick={() => navigate('/track')} style={{ fontSize: 14 }}>
          http://www.imile.com
        </a>
      </h3>
      Observação: Uma vez que o cliente concorde e aceite os serviços da nossa empresa, considera-se
      que o cliente leu detalhadamente as observações desta tabela de preço e as políticas de
      entrega, e aceita as restrições destes termos.
      <br />
      <br /> */}
      <h1>Lista de encomendas Proibidas (Embargadas)</h1>
      <div className='item'>
        1. Animais Vivos (incluindo, mas não limitado a: mamíferos, répteis, peixes, invertebrados,
        anfíbios, pássaros, insetos, larvas e pupas) ou embriões.
      </div>
      <div className='item'>
        2. Mantimentos perecíveis, alimentos e bebidas que exigem refrigeração ou outros controles
        ambientais. Qualquer produto que se quebre facilmente ou emita um odor desagradável.
      </div>
      <div className='item'>
        3. Carcaças de animais, insetos e/ou animais domésticos. Troféus de caça ou taxidermia
        inteira ou totalmente processada (seca) ou partes de animais, como marfim ou outros,
        preparados por classificador. Peles de animais, cinzas ou subprodutos de origem animal.
      </div>
      <div className='item'>
        4. Plantas e materiais vegetais, incluindo flores cortadas, ervas, caules, ramos, folhas ou
        quaisquer subprodutos de origem vegetal.
      </div>
      <div className='item'>
        5. Cadáveres humanos, órgãos ou partes do corpo, embriões humanos, restos mortais cremados
        (cinzas) ou exumados.
      </div>
      <div className='item'>
        6. Metais preciosos (barras de ouro ou joias finas) e pedras ou joias preciosas ou
        semipreciosas soltas (cortadas ou não, polidas ou não).
      </div>
      7. Dinheiro (moeda corrente, cédulas, notas em moeda estrangeira, moedas, etc.), cheques ao
      portador ou instrumentos de crédito, documentos negociáveis, notas promissórias, títulos ou
      quaisquer outros documentos com valor nominal.
      <div className='item'>
        8. Faturamento ou anúncio de bilhetes de loteria estrangeira e de loteria instantânea
      </div>
      9. Armas de qualquer tipo: originais, imitações, impressoras 3D ou armas fantasmas (sem
      números de série) e/ou peças de armas, munições etc. Ajudantes de armas automáticas (ogivas) e
      outros acionadores de disparo rápido. Qualquer arma de fogo ou outra arma.
      <div className='item'>
        10. Itens insubstituíveis, de valor inestimável ou difíceis de substituir, como arte,
        antiguidades ou outros bens.
      </div>
      <div className='item'>
        11. Dinamite, pólvora, rojões ou qualquer substância explosiva. Explosivos/dispositivos
        explosivos, como bombas, granadas ou outros dispositivos.
      </div>
      <div className='item'>
        12. Resíduos perigosos, tais como resíduos médicos, tóxicos, químicos, radioativos ou
        quaisquer resíduos perigosos na acepção do Regulamento sobre o Transporte Terrestre de
        Mercadorias Perigosas.
      </div>
      <div className='item'>13. Substâncias tóxicas, irritantes e infecciosas.</div>
      <div className='item'>
        14. Materiais químicos, corrosivos, oxidantes, inflamáveis, radioativos, magnéticos e outras
        substâncias perigosas, e quaisquer outras mencionadas no Regulamento sobre Transporte
        Terrestre de Mercadorias e Resíduos Perigosos.
      </div>
      <div className='item'>
        15. Produtos falsificados, incluindo, entre outros, marcas comerciais idênticas ou
        substancialmente indistinguíveis de marcas registradas sem a aprovação ou supervisão do
        proprietário da marca registrada (também comumente chamadas de "produtos falsificados").
      </div>
      <div className='item'>
        16. Cannabis, incluindo cannabis para uso recreativo ou medicinal, e canabidiol derivado de
        cannabis (“CBD”): qualquer produto que contenha canabinol da tireoide (“THC”) e canabinóides
        sintéticos. Plantas de cannabis cruas ou não refinadas, ou subpartes e derivados das mesmas
        (incluindo, entre outros, caules, folhas, óleos, flores e sementes de cannabis e CBD
        derivado de cannabis).
      </div>
      <div className='item'>
        17. Entorpecentes e substâncias psicotrópicas (precursores químicos e produtos químicos
        básicos).
      </div>
      <div className='item'>
        18. Amostras de laboratório (tóxicas, perigosas ou especialmente tratadas).
      </div>
      <div className='item'>
        19. Mercadorias que exigem que obtenhamos licenças ou autorizações específicas ou que
        estejam sujeitas a quaisquer restrições legais específicas sem essa permissão específica
        para transporte.
      </div>
      <div className='item'>20. Material obsceno restrito por lei.</div>
      <div className='item'>
        21. Qualquer outra mercadoria proibida pela legislação específica da matéria.
      </div>
      <div className='item'>22. Embalagem úmida, vazando ou com mau cheiro.</div>
      <div className='item'>
        23. Bens que possam causar danos ou atrasos em equipamentos, pessoal ou outros bens.
      </div>
    </Box>
  )
}
