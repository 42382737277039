import { Footer, Header } from '@/components'
import { getUrlParam, transCountry } from '@/components/PrivacyPolicy/config'
import {
  ProhibitedParcelsContent_EN,
  ProhibitedParcelsContent_PT,
} from '@/components/ProhibitedParcels/content'
import { Box } from '@mui/material'
import { graphql } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import { useEffect, useMemo, useState } from 'react'

const PrivacyPolicy = (props) => {
  const [country, setCountry] = useState<string | undefined>('')
  const [lang, setLang] = useState<string | undefined>('')

  React.useEffect(() => {
    const urlCountry =
      (getUrlParam('country') && transCountry[getUrlParam('country')]) || Cookies.get('COUNTRY')
    const urlLang = getUrlParam('language') || Cookies.get('EXPRESS_LANG')
    setCountry(urlCountry)
    setLang(urlLang)
  }, [])

  const showContent = useMemo(() => {
    let t: any = ProhibitedParcelsContent_EN

    if (lang === 'pt_BR') {
      t = ProhibitedParcelsContent_PT
    }

    return t
  }, [country, lang])

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box
        sx={{
          pt: '90px',
          padding: '90px 24px 60px',
        }}
      >
        {showContent()}
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["footer"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
